/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2021
 * 
 * Home Launcher
 * 
*/
import * as React from "react"
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import HomeMobilePage from "./HomeMobilePage";
import HomePage from "./HomePage";

export default class HomeLauncher extends Component
{
    static propTypes = {
        owner: PropTypes.object,
        hasResized: PropTypes.bool,
        location: PropTypes.object,
        device: PropTypes.object,
    }

    state = {
        launcherLoaded: false
    }

    componentDidMount()
    {
        const { owner, hasResized, location  } = this.props;

        this.setState({ launcherLoaded: true });
    }

    render()
    {
        const { launcherLoaded } = this.state;
        const { owner, hasResized, location  } = this.props;
        const { device  } = owner;

        let isMobile = device.isMobile();

        return (
            <>{launcherLoaded && <Fragment>
                {isMobile ? <HomeMobilePage owner={owner} hasResized={hasResized} location={location} />
                : <HomePage launcherLoaded={launcherLoaded} owner={owner} location={location} /> }
            </Fragment>}
            </>
        )
    }
}