import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { shuffleList } from "../../system/ArrayTools";
import Image from "../media/Image";

import { ExpoScaleEase  } from "gsap/EasePack";
import { TextPlugin } from "gsap/TextPlugin";
import LargePlayButton from "../media/LargePlayButton";
gsap.registerPlugin(TextPlugin, ExpoScaleEase);

// Seq 01: Mountain Views

export default class TimelineIntroSeq01 extends Component
{
    el(key) { return this.els[key].ref.current; } 

    static propTypes = {
        hasLazyLoaded: PropTypes.bool,
        owner: PropTypes.object.isRequired
    }

    constructor(props)
    {
        super(props);

        const { owner } = props;
        const { _owner: { device } } = owner;

        // this.ignoreVideoOverlay = true;
        this.els = {};

        this.els["bbw_1"] = { ref: createRef() }
        this.els["bb_1"] = { ref: createRef() }
        this.els["bb_2"] = { ref: createRef() }
        this.els["bb_3"] = { ref: createRef() }
        this.els["bb_4a"] = { ref: createRef() }
        this.els["bb_4b"] = { ref: createRef() }
        this.els["exh_1"] = { ref: createRef() }
        this.els["hs_1"] = { ref: createRef() }
        this.els["ep_1"] = { ref: createRef() }

        this.showSun = !device.isPhone();
        this.disableRipple = device.isFirefox() || device.isPhone();
    }

    /*
        Will set initial layers we want to show at first load, above the fold
    */
    componentDidMount()
    {
        this.updateLayerElements();

        if (!this.disableRipple) gsap.to(this.el("bbw_1"), { opacity: 1, duration: 2, ease: "linear" });
    }

    updateLayerElements()
    {
        if (this.el("bb_1")) gsap.set(this.el("bb_1"), { scale: 2, y: "-14%" });
        if (!this.disableRipple && this.el("bbw_1")) gsap.set(this.el("bbw_1"), { opacity: 0 });
        if (this.el("exh_1")) gsap.set(this.el("exh_1"), { y: "220%", scale: 4, filter: "contrast(0.1) brightness(1.25)" });
        if (this.showSun && this.el("hs_1")) gsap.set(this.el("hs_1"), { autoAlpha: 0 });
        if (this.el("ep_1")) gsap.set(this.el("ep_1"), { filter: "blur(0px) saturate(100%)", autoAlpha: 0 });
    }

    createTimeline()
    {
        const { owner: { _pageRef:page } } = this.props;

        let _is_r;
        if (process.env.NODE_ENV === 'development') {
            _is_r = this.props.owner._owner.device._is_record;
        }

        // timeline
        const scene = gsap.timeline({ id: "intro", paused: false });
        const dur = 4, durOut = 6, textDur = 2;
        const durClip = 3, durBlurOut = 5;

        const blur0 = "blur(0px)";
        const blur03 = "blur(3px)";
        const blur05 = "blur(5px)";
        const blur08 = "blur(8px)";
                
        const lpBtn = page.getElementsByClassName("large-play-button")[0];

        this.qtItems = gsap.utils.toArray(page.getElementsByClassName("wq-t"));
        this.qt01Sp = new SplitText(this.qtItems[0], { type: "words", wordsClass: "qw++" });

        // welcome quote text items
        const qt02Sp = new SplitText(this.qtItems[1], { type: "words", wordsClass: "qw++" });
        
        // mind, body and soul
        const qt03Sp = new SplitText(this.qtItems[2], { type: "words", wordsClass: "qw++" });
        
        gsap.set(this.qt01Sp.words, { autoAlpha: 0, filter: blur03 });

        scene.call(() => 
        {
            gsap.to(this.qt01Sp.words, { duration: 1, autoAlpha: 1, filter: blur0, stagger: 0.25, ease: "power1.out" });

        }, null, `start`)

        scene.addLabel("start", "-=0");
        scene.to(this.el("bb_1"), { y: "0%", opacity: 1, scale: 1, ease: "expoScale(2, 1)", duration: dur }, "start");
        scene.from(this.el("bb_2"), { y: "15%", scale: 2.2, ease: "expoScale(2.2, 1)", duration: dur }, "<");
        scene.from(this.el("bb_3"), { y: "80%", scale: 2.4, ease: "expoScale(2.4, 1)", duration: dur }, "<");
        scene.from(this.el("bb_4a"), { y: "100%", scale: 2.6, ease: "expoScale(2.6, 1)", duration: dur }, "<");
        scene.from(this.el("bb_4b"), { y: "140%", scale: 2.8, ease: "expoScale(2.8, 1)", duration: dur }, "<");

        // Text Layers: time stand stills
        scene.from(qt02Sp.words, { duration: textDur, opacity: 0, filter: blur03, stagger: 0.25 }, `start+=${dur-2}`);
        scene.to(qt02Sp.words, { duration: textDur, autoAlpha: 0, filter: blur03 }, `start+=${(dur-2) + textDur + 1}`);
        
        scene.call(() => 
        {
            gsap.to(this.qt01Sp.words, { duration: 1, autoAlpha: 0, filter: blur08, stagger: 0.25, ease: "power1.out" });

        }, null, `start+=${(dur - 2)}`)
        
        scene.addLabel("scale_out", "-=1");
        scene.to(this.el("bb_1"), { scale: 5, y: "110%", ease: "expoScale(1, 5)", duration: durOut }, "scale_out");
                
        // ext Layers: mind, body and soul
        if (process.env.NODE_ENV === 'development') {
            if (!_is_r) scene.to(qt02Sp.words, { duration: textDur, autoAlpha: 0, filter: blur03 }, "scale_out");
        }
        else scene.to(qt02Sp.words, { duration: textDur, autoAlpha: 0, filter: blur03 }, "scale_out");


        scene.from(qt03Sp.words, { duration: textDur, opacity: 0, filter: blur03, stagger: 0.25 }, "scale_out+=0.5");
        scene.to(qt03Sp.words, { duration: textDur, autoAlpha: 0, filter: blur03 }, "scale_out+=3");
        scene.to(this.el("bb_2"), { scale: 5, y: "180%", ease: "expoScale(1, 5)", duration: durOut + 2 }, "scale_out");
        scene.to(this.el("bb_3"), { scale: 5, y: "200%", ease: "expoScale(1, 5)", duration: durOut + 4 }, "scale_out");
        scene.to(this.el("bb_4a"), { scale: 15, y: "660%", ease: "expoScale(1, 15)", duration: durOut + 6 }, "scale_out");
        scene.to(this.el("bb_4b"), { scale: 15, y: "620%", ease: "expoScale(1, 15)", duration: durOut + 8 }, "scale_out");

        scene.addLabel("fade_out", "-=0");
        scene.to(this.el("bb_1"), { autoAlpha: 0, ease: "linear", duration: 3 }, `scale_out+=${(durOut) / 2}`);
        scene.to(this.el("bb_2"), { autoAlpha: 0, ease: "linear", duration: 3 }, `scale_out+=${(durOut + 2) / 2}`);
        scene.to(this.el("bb_3"), { autoAlpha: 0, ease: "linear", duration: 3 }, `scale_out+=${(durOut + 8) / 2}`);
        scene.to(this.el("bb_4a"), { autoAlpha: 0, ease: "linear", duration: 3 }, `scale_out+=${(durOut + 14) / 2}`);
        scene.to(this.el("bb_4b"), { autoAlpha: 0, ease: "linear", duration: 3 }, `scale_out+=${(durOut + 16) / 2}`);
        
        // Text Layers: are transformed, renewed, rehabilitated
        const qt04Rep = this.qtItems[3].getElementsByClassName("qtr-txt")[0];

        if (process.env.NODE_ENV === 'development') {
            const qtTm = _is_r ? 4 : 3;
            scene.addLabel("start_quote_04", `scale_out+=${qtTm}`);
        }
        else scene.addLabel("start_quote_04", `scale_out+=3`);
        
        scene.from(this.qtItems[3], { duration: 2, opacity: 0, filter: blur03 }, "start_quote_04");
        scene.to(qt04Rep, { duration: 1, opacity: 0 }, "start_quote_04+=2");
        scene.to(qt04Rep, { duration: 0, text: { value: "renewed" }, ease: "none" }, "start_quote_04+=3");
        scene.to(qt04Rep, { duration: 2, opacity: 1 }, "start_quote_04+=3");
        scene.to(qt04Rep, { duration: 1, opacity: 0 }, "start_quote_04+=5");
        scene.to(qt04Rep, { duration: 0, text: { value: "rehabilitated" }, ease: "none" }, "start_quote_04+=6");
        scene.to(qt04Rep, { duration: 2, opacity: 1 }, "start_quote_04+=6");
        
        if (process.env.NODE_ENV === 'development') {
            const qtTm2 = _is_r ? 7 : 6;
            scene.to(this.qtItems[3], { duration: 2, autoAlpha: 0, filter: blur03 }, `start_quote_04+=${qtTm2}`);
        }
        else scene.to(this.qtItems[3], { duration: 2, autoAlpha: 0, filter: blur03 }, `start_quote_04+=6`);
        
        scene.to(this.qtItems[0], { duration: 0, autoAlpha: 0 }, "start_quote_04+=10 ");
        scene.to(this.qtItems[1], { duration: 0, autoAlpha: 0 }, "start_quote_04+=10 ");
        scene.to(this.qtItems[2], { duration: 0, autoAlpha: 0 }, "start_quote_04+=10 ");

        scene.addLabel("house_in", `scale_out+=${(durOut + 12) / 2}`);
        scene.to(this.el("exh_1"), { scale: 1, y: "0%", ease: "expoScale(4, 1)", duration: dur }, `house_in`);
        scene.to(this.el("exh_1"), { filter: "contrast(1) brightness(1)", ease: "linear", duration: (dur / 2) }, `house_in+=${(dur) / 2}`);
        if (this.showSun) scene.to(this.el("hs_1"), { autoAlpha: 1, ease: "linear", duration: 2 }, `house_in+=${(dur) / 2}`);

        // Text Layers: welcome to casa lupita
        scene.addLabel("w_st", "start_quote_04+=8");

        const wT = new SplitText(".wc-txt");
        const wTB = page.querySelector(".wc-txt-body");
        const wTBSplit = new SplitText(wTB, { type: "lines" });
        scene.from(shuffleList(wT.chars), { duration: 1, opacity: 0, filter: blur05, stagger: 0.1 }, "w_st");
        scene.from(wTBSplit.lines, { duration: 1, opacity: 0, filter: blur05, stagger: 0.45 }, "w_st+=1");

        // poolside
        scene.addLabel("p_st", "w_st+=3");        
        scene.to(this.el("ep_1"), { autoAlpha: 1, filter: blur05, duration: 0 }, "p_st");
        scene.fromTo(this.el("ep_1"), { opacity: 1, y:"0%", clipPath:"polygon(0% 100%,100% 100%,100% 100%,0% 100%)" }, 
        { duration: durClip, y: "0%", clipPath:"polygon(0% 0%,100% 0%,100% 100%,0% 100%)", ease: "linear" }, "p_st+=1");
        scene.to(this.el("exh_1"), { y: "-20%", ease: "linear", duration: durClip }, "p_st+=1");
        scene.to(wT.chars, { duration: 2, autoAlpha: 0, filter: blur05, stagger: 0.1 }, "p_st+=1");
        scene.to(wTBSplit.lines, { duration: 2, autoAlpha: 0, filter: blur05, stagger: 0.45 }, "p_st+=1");
        scene.to(this.el("ep_1"), { filter: blur0, ease: "linear", duration: durBlurOut }, "p_st+=1");
        scene.to(this.el("exh_1"), { autoAlpha: 0, ease: "linear", duration: 0 }, "p_st+=4");

        // LARGE PLAY BUTTON
        scene.call(() => 
        {
            if (lpBtn.classList.contains("active")) lpBtn.classList.remove("active");
            else lpBtn.classList.add("active");

        }, null, `p_st+=1`);

        scene.to(wT.chars, { duration: 0, autoAlpha: 0, stagger: 0 }, "p_st+=4");
        scene.to(page.querySelector(".wc-txt"), { duration: 0, autoAlpha: 0 }, "p_st+=4");
        scene.to(page.querySelector(".wc-txt-body"), { duration: 0, autoAlpha: 0 }, "p_st+=4");
        
        const rDT = page.querySelector(".rehab-text > .text-regular");
        scene.from(rDT, { duration: 2, opacity: 0, ease: "linear" }, `p_st+=${durClip - 0.5}`);
        scene.from(rDT, { duration: 3, filter: blur08, ease: "linear" }, `p_st+=${durClip - 0.5}`);
        
        return scene;
    }

    playFirstSequence()
    {
        const textDur = 2;
        
        // Text Layers: there is a place
        gsap.to(this.qt01Sp.words, { delay: 1, duration: textDur, autoAlpha: 1, filter: "blur(0px)", stagger: 0.25, ease: "power1.out" });
        gsap.to(this.el("bbw_1"), { delay: 1, opacity: 1, duration: 2, ease: "linear" });
    }

    render()
    {
        const { owner, hasLazyLoaded: lz } = this.props;
        const imST = "image-layer cover-fit";
        const txlqST = "text-light quote-item";
        const tLy = "text-layer";
        
        // for recording for portrait phone ONLY
        let br = null;

        if (process.env.NODE_ENV === 'development') {
            const { _owner: { device } } = owner;
            br = device._is_record ? <br/> : null;
        }

        return <Fragment>

            <div ref={this.els["bb_4b"].ref} className={imST}>
                <Image asset={owner.assetRef("background_beach_4_b")} width={320} height={180} alt="Background" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div ref={this.els["exh_1"].ref} className={imST}>
                <Image asset={owner.assetRef("exterior_house_1")} width={320} height={200} alt="Background" hasLazyLoaded={lz} useLoad={true} />
                {this.showSun && <Image id="hs_1" ref={this.els["hs_1"].ref} width={320} height={200} 
                    asset={owner.assetRef("house_sun")} alt="Intro Image" hasLazyLoaded={lz} useLoad={true} />}
            </div>

            <div ref={this.els["bb_4a"].ref} className={imST}>
                <Image asset={owner.assetRef("background_beach_4_a")} width={320} height={180} alt="Background" hasLazyLoaded={lz} useLoad={true} />
            </div>
            
            <div ref={this.els["bb_3"].ref} className={imST}>
                <Image asset={owner.assetRef("background_beach_3")} width={320} height={180} alt="Background" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div ref={this.els["bb_2"].ref} className={imST}>
                <Image asset={owner.assetRef("background_beach_2")} width={320} height={180} alt="Background" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div ref={this.els["bb_1"].ref} className={imST}>
                <Image asset={owner.assetRef("background_beach_1")} width={320} height={180} alt="Background" hasLazyLoaded={lz} useLoad={true} />
                
                {!this.disableRipple && <><Image 
                    id="bbw" 
                    classes="show-f"
                    ref={this.els["bbw_1"].ref} 
                    asset={owner.assetRef("background_beach_water")} 
                    width={320} height={180}
                    alt="Beach water" hasLazyLoaded={lz} useLoad={true} />
                
                <svg className="rippleFx">
                    <filter id="turbulance" x="0" y="0" width="100%" height="100%">
                        <feTurbulence id="sea-filter" numOctaves="3" seed="2" baseFrequency="0.02 0.05"></feTurbulence>
                        <feDisplacementMap scale="10" in="SourceGraphic"></feDisplacementMap>
                        <animate xlinkHref="#sea-filter" attributeName="baseFrequency" dur="400s" keyTimes="0;0.5;1" 
                        values="0.02 0.06;0.04 0.08;0.02 0.06" repeatCount="indefinite"></animate>
                    </filter>
                </svg></>}
            </div>

            <div className={`${tLy} wq-t qt1`}>
                <div className={txlqST}>There is a place</div>
            </div>

            <div className={`${tLy} wq-t qt2`}>
                <div className={txlqST}>Where time stands still</div>
            </div>

            <div className={`${tLy} wq-t qt3`}>
                <div className={txlqST}>and{br} your mind, body and soul</div>
            </div>
            
            <div className={`${tLy} wq-t qt4`}>
                <div className={txlqST}><span className="qtr-txt">are transformed</span></div>
            </div>

            <div className={`${tLy} wc-txt`}>
                <h1 className="text-regular title">Welcome to Casa Lupita</h1>
            </div>

            <div className={`${tLy} wc-txt-body`}>
                <div className="text-regular">a tropical paradise nestled in the verdant hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico...</div>
            </div>

            <div ref={this.els["ep_1"].ref} className={imST} id="ep_1">
                <Image asset={owner.assetRef("exterior_poolside_1")} alt="Background" hasLazyLoaded={lz} useLoad={true} />
                <LargePlayButton  onClick={() => owner.onViewVideo()}/>
            </div>
            
            <div className={`${tLy} rehab-text`}>
                <div className="text-regular title">“Rehabilitation Redefined.”</div>
            </div>

        </Fragment>
    }

}