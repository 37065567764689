/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Element Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ElementLayer extends Component
{
    static propTypes = {
        device: PropTypes.any,                      // DeviceTool.screenSize
        type: PropTypes.string,
        id: PropTypes.string,
        className: PropTypes.string,
        backgroundColor: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
    }
    
    static defaultProps = {
        type: "ElementLayer",
        className: "",
        backgroundColor: null,
    }

    render()
    {
        const {
            device, 
            id,
            classes,
            children,
            backgroundColor,
            x, y
        } = this.props;

        let st = {}, iSt = {};
        if (device.portrait) st = { backgroundColor }
        else 
        {
            st = { backgroundColor }
            if (x && y) st.transform = `translate(${x}px, ${y}px)`;
            iSt = {}
        }

        const classItems = classNames("m-el-layer", classes, {
        });

        return (
            <Fragment>
                <div className={classItems} style={st}>
                    <div id={id} className="inner-el-layer" style={iSt}>{children}</div>
                </div>
            </Fragment>
        )
    }
}