/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Image
 * 
*/

import React, { Component, Fragment, forwardRef, createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Image extends Component
{
    static propTypes = {
        id: PropTypes.string,
        classes: PropTypes.string,
        source: PropTypes.string,
        asset: PropTypes.any,    // lazyload
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        hasLazyLoaded: PropTypes.bool,
        useLoad: PropTypes.bool,        // loades preloaded img element
    }
    
    static defaultProps = {
        classes: "",
        hasLazyLoaded: false,
        useLoad: false,
    }

    constructor(props)
    {
        super(props);

        this.imgRef = createRef();
    }

    // componentDidMount()

    render()
    {
        const { 
            id,
            classes,
            source, alt, asset,
            width,
            height,
            hasLazyLoaded,
            useLoad
        } = this.props;

        const imageStyles = {}

        const classItems = classNames("m-image", classes, {});

        // check if lazyloaded
        let imageSource = source;
        if (asset && hasLazyLoaded) imageSource = asset.src("", useLoad);

        let imgComp;
        if (hasLazyLoaded && useLoad) 
        {
            imgComp = React.createElement("img", { 
                ref: this.props.innerRef,
                id: id,
                className: classItems,
                alt: alt,
                src: imageSource.src, 
                width: width,
                height: height,
                style: imageStyles
                
            });
        }

        return (
            <Fragment>
                {imageSource && !useLoad && <img 
                    ref={this.props.innerRef}
                    id={id}
                    className={classItems} 
                    src={imageSource} 
                    alt={alt} 
                    width={width} 
                    height={height} 
                    style={imageStyles} 
                />}
                {useLoad && imgComp}
            </Fragment>
        )
    }
}

// set ref to DOM element
export default forwardRef((props, ref) => <Image innerRef={ref} {...props} />);