import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";
import Image from "../media/Image";

// Seq 02: Woman in Forest

export default class TimelineForestSeq extends Component
{
    el(key) { return this.els[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        hasLazyLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        this.els = {};
        this.els["fs_1"] = { ref: createRef() }
        this.els["fsl_1"] = { ref: createRef() }
    }

    componentDidMount()
    {
        this.updateLayerElements();
    }

    componentDidUpdate()
    {
        this.updateLayerElements();
    }

    updateLayerElements()
    {
        // womanForest
        if (this.el("fs_1")) gsap.set(this.el("fs_1"), { opacity: 0 });
        if (this.el("fsl_1")) gsap.set(this.el("fsl_1"), { opacity: 0 });
    }

    createTimeline()
    {
        const { owner } = this.props;

        // timeline
        const scene = gsap.timeline({ id: "womanForest", paused: false });
        const dur = 4, textDur = 5;
        const blur05 = "blur(5px)";
        const blur20 = "blur(20px)";
        const intro = owner.introSeq01Ref.current;

        // LARGE PLAY BUTTON
        const largePlay = owner._pageRef.getElementsByClassName("large-play-button")[0];

        // WOMAN / FOREST SCENE START
        const fST = owner._pageRef.querySelector(".fs-txt");
        scene.addLabel("fs_st", "-=0");
        scene.to(this.el("fsl_1"), { opacity: 1, ease: "linear", duration: 0 }, "fs_st");
        scene.from(this.el("fsl_1"), { scale: 1, y: "100%", ease: "linear", duration: dur }, "fs_st");
        scene.to(intro.el("ep_1"), { filter: blur20, ease: "linear", duration: dur }, "fs_st+=1");
        scene.to(this.el("fs_1"), { opacity: 1, y: "0%", ease: "linear", duration: dur }, "fs_st+=2");
        scene.to(intro.el("ep_1"), { opacity: 0, ease: "linear", duration: 0 }, `fs_st+=${dur + 2}`);
        
        // LARGE PLAY BUTTON
        scene.call(() => 
        {
            if (largePlay.classList.contains("active")) largePlay.classList.remove("active");
            else largePlay.classList.add("active");

        }, null, `fs_st+=${dur + 2}`);

        scene.from(fST, { duration: textDur, opacity: 0, filter: blur05 }, "fs_st+=3");
        scene.to(owner._pageRef, { delay: 2 }, `fs_st+=${textDur + 3}`);
        
        const rehabDefinedText = owner._pageRef.querySelector(".rehab-text");
        scene.to(rehabDefinedText, { duration: 2, opacity: 0, ease: "linear" }, `fs_st+=${1.75}`);
        
        return scene;
    }

    render()
    {
        const { owner, hasLazyLoaded: lz } = this.props;
        const imST = "image-layer cover-fit";

        return <Fragment>

            <div ref={this.els["fs_1"].ref} className={imST}>
                <Image asset={owner.assetRef("forest_scene")} alt="Forest Image" hasLazyLoaded={lz} useLoad={true} />
            </div>
            
            <div ref={this.els["fsl_1"].ref} className={imST} id="fsl_1">
                <Image asset={owner.assetRef("forest_lady_scene")} alt="Lady" hasLazyLoaded={lz} useLoad={true} />
            </div>
            
            <div className="text-layer fs-txt">
                <div className="text-regular title">Casa Lupita is a serene and IMMERSIVELY unforgettable experience like no 
                other in the world.  This fully handicap accessible, state-of-the-art facility offers relief and fun, in world-class, 
                5-star luxury, while providing health and wellbeing, both physical and mental, for the entire family.</div>
            </div>

        </Fragment>
    }

}