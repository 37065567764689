/**
 *
 * Home Page
 * 
*/
import * as React from "react"
import { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";

import HomeOverlay from "../overlay/HomeOverlay";
import HomeIntro from "../intro/HomeIntro";
import FontLoad from "../../system/FontLoad";
import { withPrefix } from 'gatsby';

export default class HomePage extends Component
{
    asset(key) { return this.props.owner.asset.get(key); } 
    assetRef(key) { return this.props.owner.asset.getRef(key); } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        location: PropTypes.object,
        launcherLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        // enable lazy load
        this._isLazy = true;
        this._hasInitPage = false;

        this._owner = props.owner;
        this._pageRef = null;
        this._splashRef = createRef();
        this._targetTo = null;

        this.state = {
            showSplash: true,
            hasLoaded: false,
            hasResized: false,
            targetTo: ""
        }

        this.onIntroLoad = this.onIntroLoad.bind(this);
    }

    /*componentDidUpdate(prevProps, prevState)
    {
        const { launcherLoaded } = this.props;

        if (launcherLoaded !== prevProps.launcherLoaded)
        {
            // this.initPage();
        }

        console.log("componentDidUpdate", this._hasInitPage)
        if (!this._hasInitPage)
        {
            this._hasInitPage = true;
            this.initPage();
        }
    }*/

    componentDidMount()
    {
        if (!this._hasInitPage)
        {
            this._hasInitPage = true;
            this.initPage();
        }
    }

    /* ----------------------------------------------*/
    /* ----- Timeline Init Functions ------------*/
    /* ----------------------------------------------*/

    initPage()
    {
        const { owner } = this.props;

        owner.fixedScroll(true);
        owner.lockScroll(true);
        owner.header.show();

        if (owner.overlay)
        {
            owner.overlay.addOverlay(<HomeOverlay 
                lazyload={this._isLazy}
                onClick={() => this.onClick()} 
                onProgress={(targetTo) => this.onProgressEnd(targetTo)}
                onClose={() => this.onClose()} 
            />, "home");
        }
    }

    /*
        Triggered from HomeOverlay

        Once user clicks on any option, we save the Target Position
    */
    onClick()
    {
        const { owner } = this.props;

        FontLoad.load(this._owner.device.resolution());

        if (!owner.header.audio.isPlaying()) owner.header.audio.playAudio();
    }

    /*
        Triggered from HomeOverlay

        Once user clicks on any option, we save the Target Position
    */
    onProgressEnd(targetTo)
    {
        this._targetTo = targetTo;

        this.setState({ hasLoaded: true });
    }

    onClose()
    {
        const { owner } = this.props;

        if (owner) owner.overlay && owner.overlay.removeOverlay("home");

        document.body.style.overflowY = "scroll";
    }

    onIntroLoad()
    {
        gsap.to(this._splashRef.current, { autoAlpha: 0, duration: 1, ease: "linear", onComplete: () => 
        {
            this.setState({ showSplash: false, targetTo: this._targetTo });
        } });
    }
    
    onResize() {}

    render()
    {
        const { owner, location } = this.props;
        const { hasLoaded, showSplash, targetTo } = this.state;

        const splashName = withPrefix(`static/images/home/1x/splash_`);
        const isWp = owner.device.isWebP();
        const splashUrl = `${splashName}@1x.${isWp ? 'webp' : 'png'}`;
        const splashLargeUrl = `${splashName}@1x.${isWp ? 'webp' : 'png'}`;
        const splashMobileUrl = `${splashName}@0.75x.${isWp ? 'webp' : 'png'}`;

        let st = "";
        if (process.env.NODE_ENV === 'development') {
            st = owner.device._is_record ? " record" : "";
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page home" + st}>
                    <div className={"page-content"}>
                        <h1>Welcome to Casa Lupita</h1>
                        <p>a tropical paradise nestled in the verdant hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico...</p>
                        <p>Casa Lupita is a serene and IMMERSIVELY unforgettable experience like no other in the world. This fully handicap accessible, state-of-the-art facility offers relief and fun, in world-class, 5-star luxury, while providing health and wellbeing, both physical and mental, for the entire family.</p>
                        <p>From the moment you are picked up in an air conditioned, handicap-ramp accessible van at the Huatulco airport, to the time you leave for home, you will experience a tranquil and emotionally fulfilling memory to last a lifetime.</p>
                        <p>The concept of Rehabilitation Redefined, is unique to Casa Lupita, and rests on the belief that the entire family of a child, or a loved one, suffering from physical disabilities, should be treated holistically.</p>
                        <p>Casa Lupita’s mission is to promote and enhance life’s experience, while improving, to the greatest extent possible, true independence and mobility.</p>
                        <p>Staffed with world-class professionals and dedicated experts, including licensed physical 
                            therapists and a Board Certified Orthopedic Surgeon, specializing in Pediatrics and Trauma rehabilitation.</p>
                        <h2>Parents can relax... recharge</h2>
                        <p>...and rekindle the romance of their relationship in this blissful property, while their child or adult family 
                            member, is professionally tended to and coached in strength and stability training, physical therapy 
                            occupational therapy, speech therapy as well as hydro therapy in a fun, playful and safe environment.</p>
                        <p>Creating lasting skills and memories to last a lifetime you can embrace the fact that you have not only 
                            made your holiday more meaningful, but that all the proceeds from your stay are contributed to 
                            funding <a href="https://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a> , 
                            a not for profit organization that offers a range of rehabilitation services to the Pacific Coast of Oaxaca.</p>
                    </div>
                    {hasLoaded && <HomeIntro owner={owner} pageRef={this._pageRef} targetTo={targetTo} location={location} onComplete={this.onIntroLoad} />}
                    {showSplash && <div className="splash" ref={this._splashRef}>
                        <img 
                            src={splashUrl} 
                            srcSet={`${splashMobileUrl} 900w, ${splashLargeUrl} 1280w`}
                            sizes={`(max-width: 900px) 100vw, (max-width: 1280px) 100vw`}
                            alt="Mountain view of the beach" />
                    </div>}
                </article>

                
            </Fragment>
        )
    }  

    componentWillUnmount()
    {
        this._pageRef = null;
    }
}