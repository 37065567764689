import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";
import Image from "../media/Image";

// Seq 04: Horizontal Scroll Seq

export default class TimelineHorizontalSeq extends Component
{
    el(key) { return this.els[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        hasLazyLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        this.els = {};

        this.els["lgos_1"] = { ref: createRef() }
        this.els["lgps_1"] = { ref: createRef() }
        this.els["lgs_1"] = { ref: createRef() }
        this.els["nwcb_1"] = { ref: createRef() }
        this.els["nwci_1"] = { ref: createRef() }
    }

    componentDidMount()
    {
        this.updateLayerElements();
    }

    componentDidUpdate()
    {
        this.updateLayerElements();
    }

    updateLayerElements()
    {
        let slidePosFrom = { x: "100%" }
        let blur05 = "blur(5px)";
      
        // horizontal
        if (this.el("lgos_1")) gsap.set(this.el("lgos_1"), { autoAlpha: 0 });
        if (this.el("lgps_1")) gsap.set(this.el("lgps_1"), { ...slidePosFrom, autoAlpha: 0 });
        if (this.el("lgs_1")) gsap.set(this.el("lgs_1"), { ...slidePosFrom, autoAlpha: 0 });
        if (this.el("nwcb_1")) gsap.set(this.el("nwcb_1"), { ...slidePosFrom, filter: blur05, autoAlpha: 0 });
        if (this.el("nwci_1")) gsap.set(this.el("nwci_1"), { ...slidePosFrom, filter: "brightness(1)", autoAlpha: 0 });
    }

    createTimeline()
    {
        const { owner: {
            vanPathSeq03Ref: {
                current: van
            }
        } } = this.props;

        // timeline
        const scene = gsap.timeline({ id: "horizontal", paused: false });
        const dur = 4, durBrightness = 3;
        const durSlideIn = 6, durSlideText = 4, durSlidePanel = 2, durSlideFadeOut = 2;
        const blur0 = "blur(0px)";

        // setup panels
        const sP01 = this.el("lgps_1").getElementsByClassName("slide-panel")[0];
        const sP01T = sP01.getElementsByClassName("inner-slide-text")[0];
        gsap.set(sP01, { opacity: 0 });
        gsap.set(sP01T, { opacity: 0 });

        const sP02 = this.el("lgs_1").getElementsByClassName("slide-panel")[0];
        const sP02T = sP02.getElementsByClassName("inner-slide-text")[0];
        gsap.set(sP02, { opacity: 0 });
        gsap.set(sP02T, { opacity: 0 });

        const sP03 = this.el("nwcb_1").getElementsByClassName("slide-panel")[0];
        const sP03T = sP03.getElementsByClassName("inner-slide-text")[0];
        gsap.set(sP03, { opacity: 0 });
        gsap.set(sP03T, { opacity: 0 });

        // setup scene
        scene.addLabel("h_start", "-=0");
        
        scene.to(this.el("lgos_1"), { autoAlpha: 1, ease: "linear", duration: 4 }, `h_start`);
        scene.to(sP01, { ease: "linear", duration: dur, opacity: 1 }, "h_start");
        
        // van forest end        
        scene.to(van.el("vf_1"), { duration: 0, autoAlpha: 0 }, `h_start+=${4}`);

        // horizontal sliding images
        scene.addLabel("hs_1", "-=0");
        let slidePosFrom = { x: "0%", y: 0 }
        let slidePosTo = { x: "-70%", y: 0 }
        scene.to(this.el("lgos_1"), { ...slidePosTo, ease: "linear", duration: durSlideIn }, "hs_1");
        scene.to(this.el("lgos_1"), { autoAlpha: 0, ease: "linear", duration: 5 }, `hs_1+=${durSlideIn - 1}`);
        
        // girl surfing in, out
        scene.to(this.el("lgps_1"), { ...slidePosFrom, ease: "linear", duration: durSlideIn }, "hs_1");
        scene.to(this.el("lgps_1"), { ...slidePosTo, ease: "linear", duration: durSlideIn }, `hs_1+=${durSlideIn}`);
        scene.to(this.el("lgps_1"), { autoAlpha: 0, ease: "linear", duration: durSlideFadeOut }, `hs_1+=${(durSlideIn * 1.95)}`);

        // girl surfing text
        scene.to(sP01T, { opacity: 1, ease: "linear", duration: durSlideText }, `hs_1+=${1}`);

        // girls swimming
        scene.addLabel("hs_3", "-=0");
        scene.to(this.el("lgs_1"), { ...slidePosFrom, ease: "linear", duration: durSlideIn }, `hs_1+=${(durSlideIn)}`);
        scene.to(this.el("lgs_1"), { ...slidePosTo, ease: "linear", duration: durSlideIn }, `hs_1+=${(durSlideIn * 2)}`);
        scene.to(this.el("lgs_1"), { autoAlpha: 0, ease: "linear", duration: durSlideFadeOut }, `hs_1+=${(durSlideIn * 2.95)}`);

        // girls swimming text/panel
        scene.to(sP02, { opacity: 1, ease: "linear", duration: durSlidePanel }, `hs_1+=${durSlideIn - 2}`);
        scene.to(sP02T, { opacity: 1, ease: "linear", duration: durSlideText }, `hs_1+=${(durSlideIn)}`);
                
        // nurse with child at the beach in, out
        scene.addLabel("hs_4", "-=0");
        scene.to(this.el("nwcb_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, `hs_1+=${(durSlideIn * 2)}`);
        scene.to(this.el("nwcb_1"), { ...slidePosFrom, ease: "linear", filter: blur0, duration: durSlideIn }, `hs_1+=${(durSlideIn * 2)}`);
        
        scene.to(this.el("nwcb_1"), { ...slidePosTo, ease: "linear", duration: durSlideIn }, `hs_1+=${(durSlideIn * 3)}`);
        scene.to(this.el("nwcb_1"), { autoAlpha: 0, ease: "linear", duration: durSlideFadeOut }, `hs_1+=${(durSlideIn * 3.95)}`);

        // nurse with child at the beach text/panel
        scene.to(sP03, { opacity: 1, ease: "linear", duration: durSlidePanel }, `hs_1+=${(durSlideIn * 3) - 2}`);
        scene.to(sP03T, { opacity: 1, ease: "linear", duration: durSlideText }, `hs_1+=${(durSlideIn * 3) - 1}`);

        // indoor nurse with child
        scene.addLabel("hs_5", "-=0");
        scene.to(this.el("nwci_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, `hs_1+=${(durSlideIn * 3)}`);
        scene.to(this.el("nwci_1"), { ...slidePosFrom, ease: "linear", duration: durSlideIn }, `hs_1+=${(durSlideIn * 3)}`);
        scene.to(this.el("nwci_1"), { filter: "brightness(5)", ease: "linear", duration: durBrightness }, `hs_1+=${(durSlideIn * 3) + durSlideIn}`);
        scene.to(this.el("nwci_1"), { autoAlpha: 0, ease: "linear", duration: durBrightness }, `hs_1+=${(durSlideIn * 3) + durSlideIn}`);

        return scene;
    }

    render()
    {
        const { owner, hasLazyLoaded:lz } = this.props;

        const imST = "image-layer cover-fit horizontal-slide-item";

        let bg = lz ? `url(${owner.assetRef("blue_slide_gradient").src()})` : null;
        const bP = { backgroundImage: bg }
        
        bg = lz ? `url(${owner.assetRef("green_slide_gradient").src()})` : null;
        const gP = { backgroundImage: bg }
        
        bg = lz ? `url(${owner.assetRef("white_slide_gradient").src()})` : null;
        const wP = { backgroundImage: bg }
        
        bg = lz ? `url(${owner.assetRef("grey_slide_gradient").src()})` : null;        
        const grP = { backgroundImage: bg }
        
        return <Fragment>

            <div ref={this.els["lgos_1"].ref} className={imST} id="lgos_1">
                <Image asset={owner.assetRef("little_girl_on_surfboard")} alt="Girl on a surfboard" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div ref={this.els["lgps_1"].ref} className={imST} id="lgps_1">
                <Image asset={owner.assetRef("little_girl_in_pool_surfing")} alt="Girl surfing in a pool" hasLazyLoaded={lz} useLoad={true} />

                <div className="slide-panel" style={bP}>
                    <div className="inner-slide-text">
                        The concept of Rehabilitation Redefined, is unique to Casa Lupita, and rests on the belief that the entire family 
                        of a child, or a loved one, suffering from physical disabilities, should be treated holistically.
                    </div>
                </div>
            </div>
            
            <div ref={this.els["lgs_1"].ref} className={imST} id="lgs_1">
                <Image asset={owner.assetRef("little_girls_swimming")} alt="A couple of girls swimming" hasLazyLoaded={lz} useLoad={true} />
                <div className="slide-panel" style={gP}>
                    <div className="inner-slide-text">
                        Casa Lupita’s mission is to promote and enhance life’s experience, while improving, to the greatest extent possible, 
                        true independence and mobility.
                    </div>
                </div>
                <div className="slide-panel shift-right" style={wP}></div>
            </div>
            
            <div ref={this.els["nwcb_1"].ref} className={imST} id="nwcb_1">
                <Image asset={owner.assetRef("nurse_with_child_beach")} alt="Trainer working with a girl on the beach" hasLazyLoaded={lz} useLoad={true} />
                <div className="slide-panel" style={grP}>
                    <div className="inner-slide-text">
                        Staffed with world-class professionals and dedicated experts, including licensed physical therapists and a Board
                        Certified Orthopedic Surgeon, specializing in Pediatrics and Trauma rehabilitation.
                    </div>
                </div>
            </div>
            
            <div ref={this.els["nwci_1"].ref} className={imST} id="nurse_with_child_indoor">
                <Image asset={owner.assetRef("nurse_with_child_indoor")} alt="Trainer working with a girl" hasLazyLoaded={lz} useLoad={true} />
            </div>             

        </Fragment>
    }

}