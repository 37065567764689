import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";
import Image from "../media/Image";

// Seq 03: Van Road Path

export default class TimelineVanPathSeq extends Component
{
    el(key) { return this.els[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        hasLazyLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        this.els = {};

        this.els["vf_1"] = { ref: createRef() }
    }

    componentDidMount()
    {
        this.updateLayerElements();
    }

    componentDidUpdate()
    {
        this.updateLayerElements();
    }

    updateLayerElements()
    {
        // vanForest
        if (this.el("vf_1")) gsap.set(this.el("vf_1"), { autoAlpha: 0, filter: "blur(0px)" });
    }

    createTimeline()
    {
        const { 
            owner: { 
                _pageRef:page, 
                forestSeq02Ref: { current:forest }, 
                horizSeq04Ref: { current:horiz } 
            } 
        } = this.props;

        // timeline
        const scene = gsap.timeline({ id: "roadForest", paused: false });
        const blur05 = "blur(5px)";
        const blur20 = "blur(20px)";
        const dur = 6, textDur = 5, durBlurOut = 5;
        const fBlurDur = 5;

        //const forestSeq = owner.forestSeq02Ref.current;
        //const horizSeq = owner.horizSeq04Ref.current;

         // woman in forest out const
        const fST = page.querySelector(".fs-txt");
        scene.addLabel("fsl_bout", "-=0");
        scene.to(forest.el("fsl_1"), { filter: blur20, ease: "linear", duration: fBlurDur }, `fsl_bout`);
        scene.to(forest.el("fs_1"), { filter: blur20, ease: "linear", duration: fBlurDur }, `fsl_bout`);
        scene.to(fST, { duration: fBlurDur, filter: blur05  }, `fsl_bout`);

        // init horizontal layers, next scene
        scene.to(horiz.el("lgps_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, "fsl_bout");
        scene.to(horiz.el("lgs_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, "fsl_bout");

        // van forest start
        scene.addLabel("p_st", `fsl_bout+=${(durBlurOut / 2)}`);
        scene.to(this.el("vf_1"), { autoAlpha: 1, duration: dur, ease: "linear" }, `p_st`);
        
        // woman in forest end
        scene.addLabel("fsl_end", `p_st+=${dur}`);
        scene.to(forest.el("fsl_1"), { autoAlpha: 0, ease: "none", duration: 0 }, `fsl_end`);
        scene.to(fST, { autoAlpha: 0, ease: "none", duration: 0 }, `fsl_end`);
        scene.to(forest.el("fs_1"), { autoAlpha: 0, ease: "none", duration: 0, onComplete: () => {} }, `fsl_end`);

        // van forest text in
        scene.addLabel("vft_st", `p_st+=${1}`);
        const vST = page.querySelector(".vf-txt");
        scene.from(vST, { duration: textDur, opacity: 0, filter: blur05  }, `vft_st`);
        
        // van forest end        
        scene.to(vST, { duration: durBlurOut, autoAlpha: 0, filter: blur20  }, `vft_st+=${textDur}`);
        scene.to(this.el("vf_1"), { duration: durBlurOut, filter: blur20 }, `vft_st+=${textDur}`);

        return scene;
    }

    render()
    {
        const { owner, hasLazyLoaded:lz } = this.props;

        return <Fragment>

            <div ref={this.els["vf_1"].ref} className="image-layer cover-fit">
                <Image asset={owner.assetRef("van_forest")} alt="Mountain view with a van on the road" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div className="text-layer vf-txt">
                <div className="text-regular title">From the moment you are picked up in an air conditioned, 
                handicap-ramp accessible van at the Huatulco airport, to the time you leave for home, you will 
                experience a tranquil and emotionally fulfilling memory to last a lifetime.</div>
            </div>

        </Fragment>
    }

}