/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Seq 06: Footer
 * 
*/

import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import Footer from "../Footer";

export default class TimelineFooterSeq extends Component
{
    element(key) { return this.elements[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        onLoad: PropTypes.func,
        hasLazyLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        this.footerRef = createRef();
    }

    createTimeline()
    {
        return this.footerRef.current.createTimeline();
    }

    render()
    {
        const { owner, hasLazyLoaded, onLoad } = this.props;

        const style = {
            visibility: hasLazyLoaded ? "visible" : "hidden"
        }

        return <Fragment>

            <div className={"footer-section m-footer"}>
                <Footer 
                    ref={this.footerRef} 
                    isIntro={true} 
                    startTime={1} 
                    owner={owner} 
                    pageRef={owner._pageRef} 
                    style={style} 
                    onLoad={() => { onLoad && onLoad() }}
                />
            </div>

        </Fragment>
    }

}