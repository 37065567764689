import React, { Component, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default class LargePlayButton extends Component
{
    static propTypes = {
        classes: PropTypes.string,
        onClick: PropTypes.func
    }

    onClick()
    {
        const { onClick } = this.props;

        onClick && onClick();
    }

    render()
    {
        const { classes } = this.props;

        const className = classNames("large-play-holder", classes);

        return <Fragment>

            <div className={className}>

                <div className="large-play-effect">
                    <div className="lp-fx-half"><div className="lp-fx-circ fx-clip"></div></div>
                    <div className="lp-fx-circ fx-fixed"></div>
                </div>

                <button className="large-play-button" aria-label="Play Video" onClick={() => this.onClick()}>
                    <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 260 260">
                        <path d="M130 0A130 130 0 1 0 260 130 130 130 0 0 0 130 0Zm55.1 141-73.9 43.7A10.1 10.1 0 0 1 96 176V88.7c0-8.8 9.2-12.2 15.2-8.7l73.9 43.7A10.1 10.1 0 0 1 185.1 141Z"></path>
                    </svg>    
                </button>

                <div className="large-play-label"><div className="large-play-text">Play Video</div></div>
            </div>

        </Fragment>
    }
}