import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";

import BaseOverlay from "./BaseOverlay";
import ModuleProgressBar from "../module/ModuleProgressBar";

export default class HomeOverlay extends BaseOverlay
{
    static propTypes = {
        lazyload: PropTypes.bool,
        onClick: PropTypes.func,
        onProgress: PropTypes.func,
        onClose: PropTypes.func
    }

    constructor(props)
    {
        super(props);

        this.overlayRef = createRef();
        this.hMenuR = createRef();
        this.bExR = createRef();
        this.pMR = (innerRef => this.pMR = innerRef);

        this.btnExTw = null; // Tween Loop
        
        this.hasClicked = false;
        this.onClick = this.onClick.bind(this);

        // this.startLzEvt = new Event('home_overlay_start');
        this.endLzEvt = new Event('home_overlay_end');

        this.onLzProg = this.onLzProg.bind(this);
        this.onLzComp = this.onLzComp.bind(this);

        this.targetTo = "";

        this.state = {
            progress: 0,
        }
    }

    componentDidMount()
    {
        // init progress bar opacity
        gsap.set(this.pMR, { autoAlpha: 0 });

        this._tween = gsap.to(this.overlayRef.current, { opacity: 1, duration: 1, ease: "power1.out", onComplete: () => 
        {
            const buttonLabel = this.bExR.current.getElementsByClassName("btn-subtitle")[0];
            this.btnExTw = gsap.timeline({ id: "progressLoop", paused: false, repeat: -1 });
            this.btnExTw.fromTo(buttonLabel, { opacity: 0.85 }, { opacity: 0.25, duration: 1, ease: "power1.out" }, "-=0");
            this.btnExTw.to(buttonLabel, { opacity: 0.85, duration: 1.5, ease: "power1.out" }, "-=0");

        } })
    }

    dispose()
    {
        super.dispose();

        window.removeEventListener('asset_progress', this.onLzProg, false);
        window.removeEventListener('asset_end', this.onLzComp, false);

        this.startLzEvt = null;
        this.endLzEvt = null;
    }

    onClick(params)
    {
        const { lazyload, onClick } = this.props;

        if (this.hasClicked) return;

        this.hasClicked = true;

        const optionButtons = [].slice.call(this.hMenuR.current.getElementsByClassName("styled-btn-01"));
        gsap.to(optionButtons, { autoAlpha: 0, duration: 0.75, stagger: 0.25, ease: "quad.out" });

        this.targetTo = params.target;

        onClick && onClick();

        window.__raid.ga.trackEvent('Website', 'Home Experience', `Target: ${this.targetTo}`);

        // check if we are lazy loading is enabled
        if (lazyload) this.showBar();
        else this.transOut();
    }

    clear()
    {
        if (this.btnExTw)
        {
            this.btnExTw.kill();
            this.btnExTw.clear();
        } 
    }

    showBar()
    {
        const { onProgress } = this.props;

        gsap.to(this.pMR, { delay: 0.65, autoAlpha: 1, duration: 0.75, ease: "power1.out", onComplete: () => {

            // window.dispatchEvent(this.startLzEvt);

            window.addEventListener('asset_progress', this.onLzProg, false);
            window.addEventListener('asset_end', this.onLzComp, false);
            
            onProgress && onProgress(this.targetTo); // target id selected

        } });
    }

    onLzProg(e)
    {
        const progressVal = e.detail.progress();
        this.setState({
            progress: progressVal
        })
    }

    onLzComp()
    {
        window.removeEventListener('asset_progress', this.onLzProg, false);
        window.removeEventListener('asset_end', this.onLzComp, false);

        this.hideProgressBar();
    }

    hideProgressBar()
    {
        gsap.to(this.pMR, { delay: 0.75, autoAlpha: 0, duration: 0.75, ease: "power1.out", onComplete: () => {
            window.dispatchEvent(this.endLzEvt);
            this.transOut();
        } });
    }

    transOut()
    {
        this.clear();

        gsap.to(this.hMenuR.current, { autoAlpha: 0, duration: 0.75, ease: "power1.out", onComplete: () => {

            gsap.to(this.overlayRef.current, { opacity: 0, duration: 0.75, ease: "power2.out", onComplete: () => 
            {
                const { onClose } = this.props;
                onClose && onClose();
            } });
        } });
    }
   
    render()
    {
        const { baseClassName } = this.props;
        const { progress } = this.state;

        return <Fragment>

            <div 
                ref={this.overlayRef} 
                role="dialog" 
                aria-label="Home Overlay" 
                className={classNames(baseClassName, "video-overlay", "home-overlay", "black")}
            >
                <div className="overlay-container">
                    
                    <div className="home-launcher" role={"document"}>

                        <div className="home-menu-container" ref={this.hMenuR}>

                            <div className={classNames("styled-btn-01", "experience-btn")}
                                ref={this.bExR}
                                onClick={() => this.onClick({ target: "continue" })}
                                onKeyPress={() => this.onClick({ target: "continue" })}
                                role="button" 
                                tabIndex={0}
                                aria-label="I want to see it all, select to start experience.">
                                
                                <div className="btn-title" aria-hidden="true">I want to see it all</div>
                                <div className="btn-subtitle" aria-hidden="true">Click to start experience</div>
                            </div>
                            <div className={classNames("styled-btn-01", "skip-to-home-btn")}
                                onClick={() => this.onClick({ target: "skip" })}
                                onKeyPress={() => this.onClick({ target: "skip" })}
                                role="button" 
                                tabIndex={0}
                                aria-label="I'm in a rush, select to skip experience.">

                                <div className="btn-title" aria-hidden="true">I'm in a rush</div>
                                <div className="btn-subtitle" aria-hidden="true">No time to "Breathe"</div>
                            </div>

                            <ModuleProgressBar progressRef={this.pMR} value={progress * 100} />
                        </div>

                    </div>
                    
                </div>
                
            </div>

        </Fragment>
    }
}