/**
 *
 * Home Page
 * 
*/

import React, { Fragment, createRef } from "react"
import PropTypes from "prop-types";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BasePage from "../pages/BasePage";
import ScrollDown from "../scrollDown/ScrollDown";

import TimelineIntroSeq01 from "./TimelineIntroSeq01";
import TimelineForestSeq from "./TimelineForestSeq";
import TimelineVanPathSeq from "./TimelineVanPathSeq";
import TimelineHorizontalSeq from "./TimelineHorizontalSeq";
import TimelineOutroSeq from "./TimelineOutroSeq";
import TimelineFooterSeq from "./TimelineFooterSeq";

import { 
    homeData, 
} from "../../data/PageAssets";

export default class HomeIntro extends BasePage
{
    element(key) { return this.elements[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        pageRef: PropTypes.object.isRequired,
        targetTo: PropTypes.string,
        onComplete: PropTypes.func   
    }

    constructor(props)
    {
        super(props);

        const { pageRef } = props;

        this._assetPath = "home";           // asset folder for page
        this._assets = homeData();          // asset data

        this._pageRef = pageRef;
        this._isLazy = true;                // enable lazy load

        this.elements = null;
        this.showVideoOverlay = false;
        this.mTl = null;
        this.bGr = null;

        // refs
        this.scrollIconRef = createRef();

        // timeline refs
        this.introSeq01Ref = createRef();
        this.forestSeq02Ref = createRef();
        this.vanPathSeq03Ref = createRef();
        this.horizSeq04Ref = createRef();
        this.outroSeq05Ref = createRef();
        this.footerSeq06Ref = createRef();

        // events
        this.onHomeOverlayEnd = this.onHomeOverlayEnd.bind(this);

        this.state = {
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();

        window.addEventListener('home_overlay_end', this.onHomeOverlayEnd, false);
        
        // hide text layers while lazy loading...
        this._pageRef.classList.add("hide-text");

        // start asset lazy load
        this.startAssets({ isLazy: true });
    }

    /*
        handles pageload and initiates intro
    */
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { onComplete, targetTo } = this.props;
        const { hasLazyLoaded } = this.state;

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();

            onComplete && onComplete();
        }

        if (targetTo !== prevProps.targetTo)
        {
            this.startIntro();
        }
    }

    dispose()
    {
        super.dispose();

        window.removeEventListener('home_overlay_end', this.onHomeOverlayEnd, false);
    }

    startAssets(settings)
    {
        const { isLazy } = settings;

        this._owner.assetManager.initialize({
            name: "assets",
            queue: this._assets,
            isLazy: this._isLazy,
            path: this._assetPath,
        });

        // override with global lazyload enabler
        const isLz = this._isLazy ? isLazy : this._isLazy;

        // start asset preload
        this._owner.assetManager.startImageLoad(isLz);
    }
    
    onHomeOverlayEnd()
    {
        window.removeEventListener('home_overlay_end', this.onHomeOverlayEnd, false);

        this._owner.fixedScroll(false);
        this._owner.lockScroll(false);
        this._owner.header.enable(false);

  
        this.setState({ hasLazyLoaded: true });
    }
    
    afterPageLoad()
    {
        if (!this.mTl) this.setupTl();
    }

    setupTl()
    {
        this._pageRef.classList.remove("hide-text");

        // get blocks
        this.bGr = [].slice.call(this._pageRef.getElementsByClassName("intro"));
        
        // init timeline
        this.mTl = gsap.timeline({ id: "main", paused: true });
        
        // add scenes
        this.mTl.add(["intro", this.introSeq01Ref.current.createTimeline()], "-=0");
        this.mTl.add(["womanForest", this.forestSeq02Ref.current.createTimeline()], "-=0");
        this.mTl.add(["roadForest", this.vanPathSeq03Ref.current.createTimeline()], "-=3");
        this.mTl.add(["horizontal", this.horizSeq04Ref.current.createTimeline()], "-=4");
        this.mTl.add(["outro", this.outroSeq05Ref.current.createTimeline()], "-=0");
        this.mTl.add(["footer", this.footerSeq06Ref.current.createTimeline()], "-=4");

        // start scroller
        this.initializeScroller();

        if (process.env.NODE_ENV === 'development') {
            if (this._owner.device._is_record)
            {
                this._owner.header.hide();
                this.scrollIconRef.current.innerRef.style.display = "none";
                const fs = () => { 
                    
                    // console.log("auto playing timeline");
                    document.body.removeEventListener("click", fs, false);
                    setTimeout(() => this.mTl.play(), 3000 );
                }
                document.body.addEventListener("click", fs, false);
            }
        }
    }

    initializeScroller()
    {
        const scrub = this._owner.device.isPhone() ? 0.2 : 1;

        ScrollTrigger.create({
            id: "mainScroller",
            trigger: this.bGr[0],                    
            animation: this.mTl,
            scrub: scrub,  
            pin: true,
            start: "top top", 
            end: (window.innerHeight * this.mTl.totalDuration()),
            markers: false
        }); 

        // timeline debug scrubber
        // GSDevTools.create({animation: this.mTl});

        /*window.requestAnimationFrame(() => {
            ScrollTrigger.refresh();
        })*/
    }

    startIntro()
    {
        const targetTo = this.props.targetTo;
        const absVCenter = this._owner.device.resolution().height / 2.5;
        const scrollEl = this.scrollIconRef.current.innerRef;
        gsap.set(scrollEl, {  x: "0%", y: "-" + absVCenter + "px", scale: 0.5, autoAlpha: 0 });
        
        const scrollScene = gsap.timeline({ paused: false });
        scrollScene.to(scrollEl, { autoAlpha: 1, scale: 1, ease: "back.out", duration: 1 }, "-=0");
        scrollScene.to(scrollEl, { y: "0%", ease: "cubic.inOut", duration: 2 }, "-=0");

        if (targetTo === "skip")
        {
            const introSeq = this.mTl.getChildren(false, false, true)[0];
            const st = ScrollTrigger.getById("mainScroller");
            const total = st.animation.totalDuration();
            const sectionStartTime = this.mTl.labels["intro"] + introSeq.labels["p_st"];
            const startProgress = sectionStartTime / total;
            const scrollTo = st.start + startProgress * (st.end - st.start);
            
            this.scrollToSmooth( scrollTo, 3000); 
        }
        else 
        {
            this.introSeq01Ref.current.playFirstSequence();
        }
    }

    scrollToSmooth( initY, duration = 300) {  
        
        const ease = t => ( t < .5 ? 2 * t * t : -1 + ( 4 - 2 * t ) * t );
        /*const TIMINGFUNC_MAP = {
            "linear": t => t,
            "ease-in": t => t * t,
            "ease-out": t => t * ( 2 - t ),
            "ease-in-out": t => ( t < .5 ? 2 * t * t : -1 + ( 4 - 2 * t ) * t )
        };
        const timingFunc = TIMINGFUNC_MAP[ timingName ];
        */

        let start = null;
        const step = ( timestamp ) => 
        {
            start = start || timestamp;
            const progress = timestamp - start,
            // Growing from 0 to 1
            time = Math.min( 1, ( ( timestamp - start ) / duration ) );
            window.scrollTo( 0, ( initY * ease( time ) ) );
            if ( progress < duration ) window.requestAnimationFrame( step );
        };
      
        window.requestAnimationFrame( step );  
    }

    render()
    {
        const { hasLazyLoaded: isLazy } = this.state;

        return (
            <Fragment>
                <div className="intro">
                    {isLazy && <div className={"layer-container"}>

                        <TimelineFooterSeq 
                            ref={this.footerSeq06Ref} 
                            owner={this}
                            hasLazyLoaded={isLazy} /> 

                        <TimelineIntroSeq01 
                            ref={this.introSeq01Ref} 
                            owner={this} 
                            hasLazyLoaded={isLazy} /> 

                        <TimelineForestSeq 
                            ref={this.forestSeq02Ref} 
                            owner={this} 
                            hasLazyLoaded={isLazy} /> 

                        <TimelineVanPathSeq 
                            ref={this.vanPathSeq03Ref} 
                            owner={this} 
                            hasLazyLoaded={isLazy} /> 

                        <TimelineHorizontalSeq 
                            ref={this.horizSeq04Ref} 
                            owner={this} 
                            hasLazyLoaded={isLazy} /> 

                        <TimelineOutroSeq 
                            ref={this.outroSeq05Ref} 
                            owner={this} 
                            hasLazyLoaded={isLazy} /> 
                        
                        <ScrollDown ref={this.scrollIconRef} />

                    </div>}
                </div>
            </Fragment>
        )
    }  
}