/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Progress Bar Component
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ModuleProgressBar extends Component
{
    static propTypes = {
        className: PropTypes.string,
        progressRef: PropTypes.any,
        value: PropTypes.number 
    }
    
    static defaultProps = {
        className: "",
    }

    constructor(props)
    {
        super(props);

        this.innerRef = null;
    }

    componentDidMount()
    {
        const { progressRef } = this.props;

        progressRef && progressRef(this.innerRef);
    }

    render()
    {
        const { 
            classes,
            value
        } = this.props;

        const progressStyles = {
            clipPath: `polygon(${value}% 0%, 0% 0%, 0% 100%, ${value}% 100%)`
        }
        
        const leftValue = 100 - value;
        const progressLeftStyles = {
            clipPath: `polygon(${leftValue}% 0%, 100% 0%, 100% 100%, ${leftValue}% 100%)`
        }

        const classItems = classNames("module-progress-bar", classes, {});

        return (
            <Fragment>
                <div className={classItems} ref={div => this.innerRef = div}>
                   
                    <div className="m-progress-container">
                        <div className="m-left-progress">
                            <div className="m-left-progress-bar" style={progressLeftStyles}></div>
                        </div>
                        <div className="m-right-progress">
                            <div className="m-right-progress-bar" style={progressStyles}></div>
                        </div>
                    </div>
                    <div className="m-label">Loading Page Assets</div>

                </div>
            </Fragment>
        )
    }
}