import React, { Component, Fragment, createRef } from "react"
import PropTypes from "prop-types";
import gsap from "gsap";
import Image from "../media/Image";

// Seq 03: Van Road Path

export default class TimelineOutroSeq extends Component
{
    el(key) { return this.els[key].ref.current; } 

    static propTypes = {
        owner: PropTypes.object.isRequired,
        hasLazyLoaded: PropTypes.bool,
    }

    constructor(props)
    {
        super(props);

        this.els = {};

        this.els["csr_1"] = { ref: createRef() }
        this.els["cbr_1"] = { ref: createRef() }
        this.els["cr_1"] = { ref: createRef() }
        this.els["nh_1"] = { ref: createRef() }
        this.els["cw_1"] = { ref: createRef() }
        this.els["fd_1"] = { ref: createRef() }
        this.els["ow_1"] = { ref: createRef() }
    }

    componentDidMount()
    {
        this.updateLayerElements();
    }

    componentDidUpdate()
    {
        this.updateLayerElements();
    }

    updateLayerElements()
    {
        // outro
        if (this.el("csr_1")) gsap.set(this.el("csr_1"), { filter: "brightness(3)", autoAlpha: 0, x: "0%" });
        if (this.el("cbr_1")) gsap.set(this.el("cbr_1"), { filter: "brightness(3)", autoAlpha: 0, x: "0%" });
        if (this.el("cr_1")) gsap.set(this.el("cr_1"), { autoAlpha: 0, x: "0%", y: "0%" });
        if (this.el("nh_1")) gsap.set(this.el("nh_1"), { autoAlpha: 0, x: "0%", y: "0%" });
        if (this.el("cw_1")) gsap.set(this.el("cw_1"), { autoAlpha: 0, scale: 1.25, x: "0%", y: "0%" });
        if (this.el("fd_1")) gsap.set(this.el("fd_1"), { autoAlpha: 0, clipPath:"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" });
        if (this.el("ow_1")) gsap.set(this.el("ow_1"), { autoAlpha: 0, clipPath:"polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)" });
    }

    createTimeline()
    {
        const { owner: { _pageRef:page } } = this.props;

        // timeline
        const scene = gsap.timeline({ id: "outro", paused: false });
        const durIn = 4, durFadeIn = 2, durBrightEnd = 4, durText = 3, durScaleIn = 8;
        const durClip = 3;
        const blur05 = "blur(5px)";
        
        const cSRT = page.querySelector(".csr-txt");
        scene.addLabel("outro_start", "-=0");
        scene.to(this.el("csr_1"), { filter: "brightness(1)", ease: "linear", duration: durIn }, "outro_start");
        scene.to(this.el("csr_1"), { autoAlpha: 1, ease: "linear", duration: durFadeIn }, "outro_start");
        scene.from(cSRT, { ease: "linear", x: "-25%", autoAlpha: 0, duration: durFadeIn }, `outro_start+=${durFadeIn}`);
        scene.to(cSRT, { delay: 2, ease: "none", duration: 0 }, `outro_start+=${durFadeIn + (durFadeIn - 2)}`);
        scene.to(cSRT, { ease: "linear", x: "25%", autoAlpha: 0, duration: durFadeIn }, `outro_start+=${durFadeIn + (durFadeIn - 2) + 2}`);

        const endST = durFadeIn + (durFadeIn - 2) + 2;
        const cBRText = page.querySelector(".cbr-txt");
        scene.addLabel("cb_1", `outro_start+=${endST}`);
        scene.to(this.el("cbr_1"), { autoAlpha: 1, filter: "brightness(1)", ease: "linear", duration: durIn }, `cb_1`);
        scene.from(cBRText, { ease: "linear", x: "-25%", autoAlpha: 0, duration: durFadeIn }, `cb_1+=${durIn}`);
        scene.to(cBRText, { delay: 2, ease: "none", duration: 0 }, `cb_1+=${durFadeIn + (durIn - 2)}`);
        scene.to(cBRText, { ease: "linear", x: "25%", autoAlpha: 0, duration: durFadeIn }, `cb_1+=${durFadeIn + (durIn - 2) + 2}`);

        const endBT = durFadeIn + (durIn - 2) + durFadeIn + 1;
        scene.to(this.el("cbr_1"), { filter: "brightness(0)", ease: "linear", duration: durBrightEnd }, `cb_1+=${endBT}`);
        
        const cNST = page.querySelector(".cns-txt");
        gsap.set(cNST, { autoAlpha: 0 });
        scene.addLabel("cr_1", `cb_1+=${durIn + durBrightEnd}`);
        scene.to(this.el("cr_1"), { autoAlpha: 1, ease: "linear", duration: durIn - 1}, "cr_1");
        scene.to(cNST, { ease: "linear", autoAlpha: 1, duration: durText }, `cr_1+=${durIn - 2}`);

        scene.addLabel("nh_1", `cr_1+=${(durIn - 2) + durText - 1}`);
        scene.to(this.el("nh_1"), { autoAlpha: 1, ease: "linear", duration: durIn - 1 }, "nh_1");
        scene.to(this.el("cr_1"), { autoAlpha: 0, ease: "linear", duration: 1 }, `nh_1+=${(durIn - 1)}`);
        scene.to(cNST, { ease: "linear", autoAlpha: 0, duration: durText }, `nh_1+=${(durIn - 2)}`);
        
        const cWST = page.querySelector(".cw-txt");
        gsap.set(cWST, { autoAlpha: 0 });
        scene.addLabel("cw_st", `nh_1+=${(durIn + durText) - 3}`);
        scene.to(this.el("cw_1"), { autoAlpha: 1, ease: "linear", duration: durFadeIn }, "cw_st");
        scene.to(this.el("nh_1"), { ease: "linear", autoAlpha: 0, duration: 0 }, `cw_st+=${durFadeIn}`);
        scene.to(this.el("cw_1"), { scale: 1, ease: "expoScale(1.25, 1)", duration: durScaleIn }, "cw_st");
        scene.to(this.el("cw_1"), { autoAlpha: 0, ease: "linear", duration: durFadeIn }, `cw_st+=${(durScaleIn - 2)}`);
        scene.to(this.el("fd_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, `cw_st+=${(durScaleIn - 3)}`);
        scene.to(this.el("csr_1"), { autoAlpha: 0, ease: "linear", duration: 0 }, `cw_st+=${(durScaleIn - 3)}`);
        scene.to(this.el("cbr_1"), { autoAlpha: 0, ease: "linear", duration: 0 }, `cw_st+=${(durScaleIn - 3)}`);
        scene.to(cWST, { ease: "linear", autoAlpha: 1, duration: durText }, `cw_st+=${durScaleIn - 6 }`);

        const dST = page.querySelector(".fd-txt");
        gsap.set(dST, { autoAlpha: 0 });

        const oT = page.querySelector(".whc-txt");
        gsap.set(oT, { autoAlpha: 0 });

        scene.addLabel("ofd_1", `cw_st+=${durText + (durScaleIn - 6) + 1 }`);
        scene.to(cWST, { ease: "linear", autoAlpha: 0, duration: 1 }, `ofd_1`);
        scene.to(dST, { ease: "linear", autoAlpha: 1, duration: 1 }, `ofd_1+=${durFadeIn}`);
        scene.to(this.el("fd_1"), { filter: blur05, ease: "linear", duration: durFadeIn }, `ofd_1+=3`);
        scene.to(this.el("ow_1"), { autoAlpha: 1, ease: "linear", duration: 0 }, `ofd_1`);
        scene.to(this.el("ow_1"), { duration: durClip, clipPath:"polygon(0% 0%,100% 0%,100% 100%,0% 100%)", ease: "linear" }, `ofd_1+=${durFadeIn + 2}`);
        scene.to(dST, { ease: "linear", autoAlpha: 0, duration: 1 }, `ofd_1+=${(durFadeIn + 2)}`);
        scene.to(oT, { ease: "linear", autoAlpha: 1, duration: 1 }, `ofd_1+=${durFadeIn + 4}`);
        scene.to(this.el("fd_1"), { autoAlpha: 0, ease: "linear", duration: 0 }, `ofd_1+=${durFadeIn + 5}`);
        scene.to(this.el("ow_1"), { delay: 2, y: "-100%", ease: "linear", duration: 4 }, `ofd_1+=${durFadeIn + 5}`);
        scene.to(oT, { delay: 2, ease: "linear", y: "-50%", autoAlpha: 0, duration: 1 }, `ofd_1+=${durFadeIn + 5}`);
        
        // enable footer visibility for next section
        const fS = page.getElementsByClassName("footer-section")[0];
        scene.from(fS, { autoAlpha: 0, y: window.innerHeight, ease: "linear", duration: 0 }, `ofd_1+=${durFadeIn + 5}`);
        scene.to(this.el("ow_1"), { autoAlpha: 0, ease: "linear", duration: 0 }, `ofd_1+=${durFadeIn + 11}`);

        return scene;
    }

    render()
    {
        const { owner, hasLazyLoaded: lz } = this.props;
        const imST = "image-layer cover-fit";
        const txST = "text-regular title";
        const spST = "text-regular slide-panel";
        const spiST = "panel-inner-text";

        return <Fragment>

            <div ref={this.els["csr_1"].ref} className={imST} id="csr_1">
                <Image asset={owner.assetRef("couple_spa_relax")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} useLoad={true} />
                <div className={spST}>
                    <div className={spiST}>Parents can relax...</div>
                </div>
            </div>
            
            <div ref={this.els["cbr_1"].ref} className={imST} id="cbr_1">
                <Image asset={owner.assetRef("couple_beach_relax")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} useLoad={true} />
                <div className={spST}>
                    <div className={spiST}>...recharge</div>
                </div>
            </div>
            
            <div ref={this.els["cr_1"].ref} className={imST} id="cr_1">
                <Image asset={owner.assetRef("couple_romance")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} useLoad={true} />
            </div>
            
            <div ref={this.els["nh_1"].ref} className={imST} id="nh_1">
                <Image asset={owner.assetRef("night_house")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} useLoad={true} />
            </div>

            <div ref={this.els["fd_1"].ref} className={imST} id="fd_1">
                <Image asset={owner.assetRef("family_dining")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} />
            </div>

            <div ref={this.els["cw_1"].ref} className={imST} id="cw_1">
                <Image asset={owner.assetRef("child_worker")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} />
            </div>
            
            <div ref={this.els["ow_1"].ref} className={imST} id="ow_1">
                <Image asset={owner.assetRef("outro_wheelchair")} alt="Couple relaxing in a spa" hasLazyLoaded={lz} />
            </div>

            <div className="text-layer csr-txt">
                <div className={txST}>Parents can relax...</div>
            </div>
            
            <div className="text-layer cbr-txt">
                <div className={txST}>...recharge</div>
            </div>

            <div className="text-layer cns-txt">
                <div className={txST}>...and rekindle the romance of their relationship in this blissful property...</div>
            </div>

            <div className="text-layer cw-txt">
                <div className={txST}>...while their child or adult family member, is professionally tended to and coached in strength and 
                stability training, physical therapy, occupational therapy, speech therapy as well as hydro therapy in a fun, playful and safe environment.</div>
            </div>
            
            <div className="text-layer fd-txt">
                <div className={txST}>Creating lasting skills and memories to last a lifetime...</div>
            </div>
            
            <div className="text-layer whc-txt">
                <div className={txST}>...you can embrace the fact that you have not only made your holiday more meaningful, 
                but that all the proceeds from your stay are contributed to funding <a href="http://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">Lupita Project</a>, a not for 
                profit organization that offers a range of rehabilitation services to the Pacific Coast of Oaxaca</div>
            </div>

        </Fragment>
    }

}