/**
 *
 * Home Mobile Page
 * 
*/
import * as React from "react"
import { createRef, Fragment } from "react"

import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { shuffleList } from "../../system/ArrayTools";

import BasePage from "./BasePage";
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import LargePlayButton from "../media/LargePlayButton";
import ElementLayer from "../module/layers/ElementLayer";
import FontLoad from "../../system/FontLoad";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    homeMobileData, 
} from "../../data/PageAssets";

export default class HomeMobilePage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                    // lazy loading 
        this._assetPath = "home_mobile";        // asset folder for page
        this._assets = homeMobileData();        // asset data
        
        this.scrollVRef = createRef();          // best experience button
        this.startBref = createRef();           // best experience button
        this.btnStartLp = null;                 // best exp animation
        this.isBest = false;                    // best experience flag
        this.isTitle = false;                   // title animation start flag
        this.tl = null;                         // title animation
        this.wT = null;                         // welcome title text
        this.wTB = null;                        // welcome body text
        this.wTBS = null;                       // welcome body split text
        this.isVidEnd = false;                  // video has ended flag

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
            playVideo: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded:lz } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            // remove overlays
            const isSSR = typeof window === "undefined";
            if (!isSSR) this._owner.overlay.removeAll();

            this.initPage();
            this.initTitle();

            const btn = this.startBref.current;
            this.btnStartLp = gsap.timeline({ paused: false, repeat: -1 });
            this.btnStartLp.fromTo(btn, { opacity: 0.85 }, { opacity: 0.25, duration: 2, ease: "sine.out" }, "-=0");
            this.btnStartLp.to(btn, { opacity: 0.85, duration: 2, ease: "sine.out" }, "+=0");
            return;
        }

        if (lz !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    onVideoTime(p)
    {
        // console.log("onVideoTime")
        const { currentTime: cT } = p;
        // if (!this.isTitle) 
        // console.log("video", cT);
        // once we hit the 22 sec mark, start animation
        if (cT > 22 && !this.isTitle) this.startTitleAnim();

        if (cT > 1 && !this.isBest) 
        {
            // this.fadeStartBtn();
            gsap.to(this.startBref.current, { autoAlpha: 0, duration: 0.75 });
        }
    }

    initTitle()
    {
        const mT = this._pageRef.getElementsByClassName("module-title")[0];
        const mST = this._pageRef.getElementsByClassName("module-subtitle")[0];

        // console.log("initTitle")

        this.wT = new SplitText(mT);
        this.wTB = mST.querySelector(".sub-heading");
        this.wTBS = new SplitText(this.wTB, { type: "lines" });
        this.tl = gsap.timeline({ paused: true });
        this.tl.fromTo(shuffleList(this.wT.chars), { autoAlpha: 0, filter: "blur(5px)" }, { duration: 1, autoAlpha: 1, filter: "blur(0px)", stagger: 0.1 }, "-=0");
        this.tl.fromTo(this.wTBS.lines, { autoAlpha: 0, filter: "blur(5px)" }, { duration: 1, autoAlpha: 1, filter: "blur(0px)", stagger: 0.45, onComplete: () => {
            this.isVidEnd = true;
            this.showScroll();
        } }, "-=0.25");
    }

    startTitleAnim()
    {
        const mT = this._pageRef.getElementsByClassName("module-title")[0];
        const mST = this._pageRef.getElementsByClassName("module-subtitle")[0];
        mT.classList.add("show");
        mST.classList.add("show");

        this.isTitle = true;
        this.tl.play();
    }

    onInteraction()
    {
        if (this._isMove) return;

        super.onInteraction();

        window.requestAnimationFrame(() => {
            FontLoad.load(this._owner.device.resolution());
        })
    }

    fadeStartBtn()
    {
        if (this.isBest) return;
        if (!this._owner.header.audio.isPlaying()) this._owner.header.audio.playAudio();

        this.isBest = true;
        this.btnStartLp && this.btnStartLp.kill();
        gsap.to(this.startBref.current, { autoAlpha: 0, duration: 0.75 });

        this.setState({ playVideo: true })
    }

    onResize(d)
    {
        super.onResize();

        this.wT.revert();
        this.wTBS.revert();
        if (!this.isVidEnd) this.initTitle();
    }

    onCloseVideo()
    {
        super.onCloseVideo();

        this.scrollVRef.current.classList.add("show-scroll");
    }

    dispose()
    {
        this.tl && this.tl.kill();
        this.btnStartLp && this.btnStartLp.kill();
        super.dispose();
    }

    render()
    {
        const { 
            hasLoaded, 
            hasLazyLoaded:lz, 
            hasResized:rz,
            playVideo
        } = this.state;

        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            hasUpdated: playVideo,
            device: this._devSize
        }

        const { device  } = this._owner;

        const isP = device.isPortrait();
        const bgVid = device.isTablet() ? "home_hd.mp4" : "home_ultra.mp4";
        const addedTxt = "Creating lasting skills and memories to last a lifetime you can embrace the";
        const vF = this.withPrefix(`static/videos/home/${bgVid}`);

        return (
            <Fragment>
                
                <article ref={div => this._pageRef = div} className={"page home-mobile"}>
                    <div className={"page-content"}>
                        <h1>Welcome to Casa Lupita</h1>
                        <p>a tropical paradise nestled in the verdant hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico...</p>
                        <p>Casa Lupita is a serene and IMMERSIVELY unforgettable experience like no other in the world. This fully handicap accessible, state-of-the-art facility offers relief and fun, in world-class, 5-star luxury, while providing health and wellbeing, both physical and mental, for the entire family.</p>
                        <p>From the moment you are picked up in an air conditioned, handicap-ramp accessible van at the Huatulco airport, to the time you leave for home, you will experience a tranquil and emotionally fulfilling memory to last a lifetime.</p>
                        <p>The concept of Rehabilitation Redefined, is unique to Casa Lupita, and rests on the belief that the entire family of a child, or a loved one, suffering from physical disabilities, should be treated holistically.</p>
                        <p>Casa Lupita’s mission is to promote and enhance life’s experience, while improving, to the greatest extent possible, true independence and mobility.</p>
                        <p>Staffed with world-class professionals and dedicated experts, including licensed physical 
                            therapists and a Board Certified Orthopedic Surgeon, specializing in Pediatrics and Trauma rehabilitation.</p>
                        <h2>Parents can relax... recharge</h2>
                        <p>...and rekindle the romance of their relationship in this blissful property, while their child or adult family 
                            member, is professionally tended to and coached in strength and stability training, physical therapy 
                            occupational therapy, speech therapy as well as hydro therapy in a fun, playful and safe environment.</p>
                        <p>Creating lasting skills and memories to last a lifetime you can embrace the fact that you have not only 
                            made your holiday more meaningful, but that all the proceeds from your stay are contributed to 
                            funding <a href="https://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a> , 
                            a not for profit organization that offers a range of rehabilitation services to the Pacific Coast of Oaxaca.</p>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps} name="hero">
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("video_hero")}
                                    imageAlt={"Mountain view"}
                                    video={{ desktop: vF, loop: false, autoLoad: playVideo, delay: this._isPhone ? 0 : 0 }}
                                    showGradient={false}
                                    onTime={(p) => this.onVideoTime(p)}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Welcome to Casa Lupita"
                                    subheading={`a tropical paradise nestled in the verdant hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico...`}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down"><ScrollDown /></div>
                                <div ref={this.startBref} className="bxp-btn" role="button" tabIndex={0} onKeyPress={()=>this.fadeStartBtn()} onClick={()=>this.fadeStartBtn()}>
                                    <div className="styled-btn-01 bxp-text"><span className="bxp-clr">Click here</span><br/>for best experience</div>
                                </div>
                            </Module>

                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    classes="first-pad lady-img"
                                    alt="Lady in forest"
                                    asset={this.assetRef("forest_lady_shot")}
                                    x={-234} y={107} width={1300} height={2188}
                                />

                                <TextLayer classes="grey" x={1109} y={207} width={535} height={530} fontSize={150}>
                                    Casa Lupita is a serene and IMMERSIVELY unforgettable experience like no other in the world.  
                                    This fully handicap accessible, state-of-the-art facility offers relief and fun, in world-class, 
                                    5-star luxury, while providing health and wellbeing, both physical and mental, for the entire family.
                                </TextLayer>

                                <ImageLayer 
                                    classes="hide-portrait"
                                    alt="Family playing in pool"
                                    asset={this.assetRef("forest_pool_side")}
                                    x={590} y={944} width={1330} height={1351}
                                />

                                <ImageLayer 
                                    classes="hide-portrait"
                                    alt="Lady looking behind"
                                    asset={this.assetRef("forest_lady_alpha")}
                                    x={-234} y={107} width={1300} height={2188}
                                />

                            </ModuleLayer>

                            <ModuleLayer 
                                backgroundColor="white" 
                                paddingBottom={107} 
                                ignoreTopPadding={true} 
                                {...mProps}
                            >
                                <ImageLayer 
                                    mobile={{ order: 2 }}
                                    alt="Casa Lupita pool side"
                                    asset={this.assetRef("exterior_poolside")}
                                    x={0} y={0} width={1920} height={1403}
                                >
                                    <ElementLayer device={this._device.screenSize}>
                                        <LargePlayButton onClick={(e) => this.onViewVideo()} />
                                        <div ref={this.scrollVRef} className="module-scroll-down home-video"><ScrollDown /></div>
                                    </ElementLayer>
                                </ImageLayer>

                                <TextLayer mobile={{ order: 1 }} classes="white rehab-title" x={104} y={142} width={740} height={120} fontSize={241}>
                                    “Rehabilation Redefined”
                                </TextLayer>

                                

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <TextLayer classes="ft-27 bottom-space" x={123} y={-3} width={583} height={281}>
                                    From the moment you are picked up in an air conditioned, handicap-ramp accessible van at the Huatulco airport, to the 
                                    time you leave for home, you will experience a tranquil and emotionally fulfilling memory to last a lifetime.
                                </TextLayer>

                                <ImageLayer 
                                    alt="Trainer pushing girl on a wheelchair to a van"
                                    asset={this.assetRef("wheelchair_in_van")}
                                    x={0} y={318} width={706} height={411}
                                />

                                <ImageLayer 
                                    alt="Mountain view showing van driving down the road"
                                    asset={this.assetRef("mountain_road")}
                                    x={814} y={0} width={1106} height={729}
                                />

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <TextLayer classes="ft-27 bottom-space" x={960} y={-3} width={854} height={187}>
                                    The concept of Rehabilitation Redefined, is unique to Casa Lupita, and rests on the belief that the entire family of a 
                                    child, or a loved one, suffering from physical disabilities, should be treated holistically.
                                </TextLayer>

                                <ImageLayer 
                                    alt="Girl training to surf"
                                    asset={this.assetRef("girl_ontop_board")}
                                    x={0} y={0} width={853} height={668}
                                />

                                <ImageLayer 
                                    alt="Family playing in the spa"
                                    asset={this.assetRef("family_in_spa")}
                                    x={960} y={230} width={854} height={438}
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    mobile={{ order: 2 }}
                                    alt="Girl surfing a wave"
                                    asset={this.assetRef("girl_surf_beach")}
                                    x={0} y={0} width={1920} height={1000}
                                />

                                <TextLayer mobile={{ order: 1 }} classes="bottom-space" x={174} y={83} width={893} height={293} fontSize={149}>
                                    Casa Lupita’s mission is to promote and enhance life’s experience, while improving, 
                                    to the greatest extent possible, true independence and mobility.
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer 
                                backgroundColor="white" 
                                paddingBottom={107} 
                                ignoreTopPadding={true} 
                                {...mProps}
                            >
                                <ImageLayer 
                                    mobile={{ order: 2 }}
                                    alt="Girl being trained"
                                    asset={this.assetRef("trainer_girl_indoor")}
                                    x={0} y={0} width={1920} height={1678}
                                />

                                <TextLayer mobile={{ order: 1 }} classes="grey bottom-space" x={123} y={89} width={542} height={780} fontSize={173}>
                                    Staffed with world-class professionals and dedicated experts, including licensed physical 
                                    therapists and a Board Certified Orthopedic Surgeon, specializing in Pediatrics and Trauma rehabilitation.
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <TextLayer classes="relax-text" x={1066} y={20} width={506} height={152} fontSize={190}>
                                    Parents can relax...
                                </TextLayer>
                                
                                <ImageLayer 
                                    alt="Couple enjoying the spa"
                                    asset={this.assetRef("couple_spa")}
                                    x={0} y={0} width={960} height={544}
                                />

                                <TextLayer classes="grey recharge-text" x={1322} y={80} width={460} height={152} fontSize={345}>
                                    recharge
                                </TextLayer>

                                <ImageLayer 
                                    alt="Couple enjoying the beach"
                                    asset={this.assetRef("couple_beach_shore")}
                                    x={1066} y={274} width={854} height={542}
                                />

                                <TextLayer classes="bottom-space" x={123} y={642} width={755} height={235} fontSize={203}>
                                    ...and rekindle the romance of their relationship in this blissful property...
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={107} ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    alt="Lady enjoying her wine"
                                    asset={this.assetRef("lady_wine")}
                                    x={0} y={0} width={1066} height={685}
                                />

                                <ImageLayer 
                                    alt="Casa Lupita night entrance"
                                    asset={this.assetRef("house_night_entrance")}
                                    x={1173} y={187} width={747} height={498}
                                />

                            </ModuleLayer>

                            <ModuleLayer 
                                backgroundColor="white" 
                                paddingBottom={107} 
                                ignoreTopPadding={true} 
                                {...mProps}
                            >
                                <ImageLayer 
                                    mobile={{ order: 2 }}
                                    alt="Trainer with a little girl"
                                    asset={this.assetRef("trainer_girl_zoom")}
                                    x={0} y={0} width={1920} height={1080}
                                />

                                <TextLayer classes="white bottom-space" mobile={{ order: 1 }} x={99} y={80} width={768} height={301} fontSize={173}>
                                    ...while their child or adult family member, is professionally tended to and coached in strength and 
                                    stability training, physical therapy...
                                </TextLayer>
                                
                                <TextLayer mobile={{ order: 3 }} classes="white bottom-space strip-p-bottom" x={1066} y={746} width={750} height={301} fontSize={173}>
                                    ...occupational therapy, speech therapy as well as hydro therapy in a fun, playful and safe environment.
                                    {isP && <p>{addedTxt}</p> }
                                </TextLayer>

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" paddingBottom={30} ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    alt="Family enjoying lunch at the dining table"
                                    asset={this.assetRef("family_lunch")}
                                    x={0} y={0} width={960} height={540}
                                />
                                
                                <TextLayer classes="bottom-space" x={1066} y={91} width={804} height={292} fontSize={143}>
                                    {!isP && <>{addedTxt}</> } fact that you have not only made your holiday more meaningful, but that all the proceeds from your stay 
                                </TextLayer>
                                
                                <ImageLayer 
                                    alt="A wheelchair by the pool"
                                    asset={this.assetRef("wheelchair_by_pool")}
                                    x={1066} y={439} width={854} height={480}
                                />

                                <TextLayer classes="bottom-space" x={115} y={621} width={830} height={381} fontSize={143}>
                                    <>are contributed to funding <a href="https://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a>, a not 
                                    for profit organization that offers a range of rehabilitation services to the Pacific Coast of Oaxaca.
                                    </>
                                </TextLayer>
                                

                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>
                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}